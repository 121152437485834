body {
  background: url("../assets/images/Home_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.overlay {
  background-color: rgba(102, 100, 100, 0.7);
  /* top:0; */
  width: 100%;
  height: 100%;
  /* position: absolute; */
  /* z-index: 1; */
}
#home {
  /* background: url("../assets/images/Home_bg.jpg"); */
  /* height: 100vh; */
  height: 100%;
}
.container__iprx {
  display: grid;
  align-items: center;
  align-content: center;
  height: 100%;
  width: 98%;
  z-index: 5;
}
.header__title {
  font-size: 20px !important;
}
.Headline {
  font-size: 48px;
  text-align: center;
  margin: 0;
  color: white;
}
.HeadlinesubText {
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  margin: 0;
  margin-top: 1%;
  color: white;
}
.headline {
  font-size: 25px;
  line-height: 15px;
  color: white;
}
.image-wrapper {
  text-align: center;
}

.image {
  text-align: center;
  margin: 10px 5px;
  height: 120px;
  width: 333px;
  /* margin-top: 5%; */
}
.buttonWrapper {
  display: flex;
  justify-content: space-evenly;
}
/* button css */

.btn1 {
  /* background-color: #B2BB1E; */
  border: none;
  color: white;
  cursor: pointer;
  /* max-width: 33%; */
  border-radius: 25px;
  padding: 2%;
  max-width:30%;
  font-size: 16px;
  height: 300px;
}
.btn1 p {
  font-size: 17px;
}
.btn2 {
  /* background-color: #B2BB1E; */
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  justify-self: center;
  max-width: 200px;
  height: 80px;
  border-radius: 25px;
}
.btn2 p {
  font-size: 16px;
  margin: 2%;
}
.btn3 {
  /* background-color: #B2BB1E; */
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  width: 250px;
  max-width: 250px;
  height: 120px;
  border-radius: 25px;
}
.btn3 p {
  font-size: 16px;
}
.btn4 {
  /* background-color: #B2BB1E; */
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  min-width:30%;
  height: 300px;
  border-radius: 25px;
  max-width: 30%;
  padding: 2%;
}
.btn4 p {
  font-size: 20px;
  margin-top: -5px;
}

.features {
  font-size: 20px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
}
.icon-logo {
  display: block;
  max-width: 65px;
  height: 65px;
  margin: auto;
  margin-bottom: 15px;
}
.icon-logo-vertical {
  display: block;
  max-width: 35px;
  height: 35px;
  margin: auto;
  margin-top: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.top {
  padding-top: 25%;
}
.one {
  position: absolute;
  top: 70%;
  left: 25%;
  transform: translate(-50%, -100%);
  height: 50px;
  width: 200px;
}
.two {
  position: absolute;
  top: 70%;
  left: 75%;
  transform: translate(-50%, -100%);
  height: 50px;
  width: 200px;
}
.btn-floating.btn-large {
  width: 180px;
  height: 50px;
}

.centerButtonWrapper {
  display: grid;
  justify-content: center;
  align-content: flex-end;
  grid-row-gap: 20px;
  max-width: 30%;
}
/* Media Query for Responsiveness*/

@media screen and (min-width: 1366px) {
  .HeadlinesubText {
    margin-top: 0 !important;
  }
  .image {
    margin-top: 0 !important;
    height: 80px;
    object-fit: contain;
  }
  .headline p {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 1000px) {
  .buttonWrapper {
    display: grid;
    justify-items: center;
    grid-row-gap: 30px;
    margin-top: 30px;
  }
  .container__iprx {
    height: unset;
    margin-top: 50px;
    padding: 5px;
  }
  .Headline {
    font-size:25px;
    font-weight: bolder;
  }
  .HeadlinesubText {
    font-size: 18px;
    font-weight: 400;
  }
  .image {
    width: 200px;
    /* height: 80px; */
    object-fit: contain;
  }
  .headline {
    font-size: 20px;
    line-height: 25px;
    margin-top: -20px;
  }
  .headline p {
    margin:10px;
  }
  .btn1 {
    width: 80%;
    max-width: 400px;
  }
  .btn4 {
    width: 80%;
    max-width: unset;
  }
  body {
    background-repeat: repeat-y;
  }
  .overlay {
    height: unset;
  }
}
@media screen and (min-height: 768px) {
  #home {
    /* background: url("../assets/images/Home_bg.jpg"); */
    height: 100vh;
    /* height: 100%; */
  }
}