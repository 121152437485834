.view__root {
    height: 100vh;
}
.view__iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
}
.button__wrapper {
    position: absolute;
    top:40%;
    left:0;
    display: grid;
    z-index: 5;
}
.button__wrapper button {
    background-color: green;
    height: 70px;
    width:70px;
    border-bottom-color: white;
    border-right-color: transparent;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 5;
}
.buttonImage {
    width: 50px;
    height: 50px;
}